import React, { useRef, useEffect} from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '../../shared/theme/theme';
import HeroSection from './HeroSection';
import Departamente from './Departamente';
// import NextEvent from './NextEvent';
import Footer from './Footer';
import NavBar from './HeroSection/components/NavBar';
import BTT from './Props';
import Sponsors from './Sponsors';
import Contact from './Contact';
import Evenimente from './Carousel/Evenimente';
import Team from './Team';
import './../../App.css'

interface RedirectProp
{
  redirect: string;
}

const scrollToSection = (ref: React.RefObject<HTMLDivElement>) =>
  {
      if (ref.current)
      {
          smoothScroll(ref.current);
      }
  };

const smoothScroll = (target: HTMLElement) =>
  {
      const targetPosition = target.getBoundingClientRect().top + window.scrollY - 75;
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 1000;
      let start: number | null = null;
  
      const easeInOutCubic = (t: number) =>
      {
          return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
      };
  
      const step = (timestamp: number) =>
      {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const percent = Math.min(progress / duration, 1);
          const easedPercent = easeInOutCubic(percent);
          window.scrollTo(0, startPosition + distance * easedPercent);
          if (progress < duration)
          {
              requestAnimationFrame(step);
          }
      };
  
      requestAnimationFrame(step);
  };
  

const Home: React.FC<RedirectProp> = ({redirect}) =>
{
  const heroRef = useRef<HTMLDivElement>(null);
  const departmentsRef = useRef<HTMLDivElement>(null);
  // const nextEventRef = useRef<HTMLDivElement>(null);
  const leadershipRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);
  const sponsorsRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  useEffect(() =>
    {
      if (redirect)
      {
        switch(redirect)
        {
          case "acasa":
            scrollToSection(heroRef);
            break;
          case "departments":
            scrollToSection(departmentsRef);
            break;
          // case "nextEvent":
          //   scrollToSection(nextEventRef);
          //   break;
          case "leadership":
            scrollToSection(leadershipRef);
            break;
          case "events":
            scrollToSection(eventsRef);
            break;
          case "sponsors":
            scrollToSection(sponsorsRef);
            break;
          case "faq":
            scrollToSection(faqRef);
            break;
          case "contact":
            scrollToSection(contactRef);
            break;
          default:
            break;
        }
      }
    });

  return (
    <ThemeProvider theme={theme}>
      <NavBar 
        heroRef={heroRef}
        departmentsRef={departmentsRef} 
        leadershipRef={leadershipRef}
        eventsRef={eventsRef}
        sponsorsRef={sponsorsRef}
        faqRef={faqRef}
        contactRef={contactRef}
      />

      <HeroSection ref={heroRef} />
      <Departamente ref={departmentsRef} />
      {/* <NextEvent ref={nextEventRef}/> */}
      <Evenimente ref={eventsRef}/>
      <Team ref={leadershipRef}/>
      <Sponsors ref={sponsorsRef}/>
      <Contact ref={contactRef}/>
      <BTT />
      <Footer/>
    </ThemeProvider>
  );
}

export default Home;
