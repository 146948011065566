import React, { useRef } from "react";
import { Grid2, Stack, Typography, Divider } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "../../shared/theme/theme";
import NavBar from "../Home/HeroSection/components/NavBar";
import { Footer } from "../Home";
import BTT from "../Home/Props"

const FAQ: React.FC = () => {
  const heroRef = useRef<HTMLDivElement>(null);
  const departmentsRef = useRef<HTMLDivElement>(null);
  const leadershipRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);
  const sponsorsRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <NavBar
        heroRef={heroRef}
        departmentsRef={departmentsRef}
        leadershipRef={leadershipRef}
        eventsRef={eventsRef}
        sponsorsRef={sponsorsRef}
        faqRef={faqRef}
        contactRef={contactRef}
      />

      <Grid2
        container
        ref={faqRef}
        sx={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: '80px',
        }}
      >
        <Grid2
          size={{ xs: 12, md: 10 }}
          sx={{
            backgroundColor: "rgb(17, 103, 177, 0.4)",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            borderRadius: "40px",
            flexDirection: "column",
            gap: "24px",
            padding: "16px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "clamp(1.5rem, 30vw, 1.5rem)",
                md: "clamp(1.5rem, 30vw, 3rem)",
              },
              alignSelf: { xs: "center", md: "center" },
              textAlign: "center",
              paddingY: "10px",
              paddingX: "3px",
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Divider
            sx={{
              borderColor: "#D0EFFF",
              borderWidth: "1px",
              borderRadius: "20px",
            }}
          />
          <Grid2
            sx={{
              flexDirection: "row",
              width: "100%",
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <Stack
              sx={{
                gap: "32px",
                justifyItems: "center",
                textAlign: "center",
              }}
            >
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Ce reprezintă LSAC?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Liga Studenților Facultății de Automatică și Calculatoare
                  (LSAC) s-a înființat în anul 2005, fiind o organizație
                  studențească, non-guvernamentală, non-profit și apolitică.
                  Organizația noastră are ca scop promovarea și apărarea
                  intereselor studenților din Facultatea de Automatică și
                  Calculatoare. De asemenea, LSAC realizează diverse programe și
                  proiecte interactive pentru a putea ocupa timpul liber al
                  studenților într-un mod plăcut, distractiv și organizat.
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Cum pot ajuta dacă nu sunt membru?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  O metodă simplă de a ne susține este redirecționarea a 3,5%
                  din impozitul pe venit către organizația noastră. Această
                  contribuție ne ajută să continuăm proiectele educaționale și
                  activitățile destinate comunității studențești. De asemenea,
                  poți sprijini inițiativele noastre prin promovarea acestora
                  sau prin parteneriate cu alte organizații. Orice ajutor
                  contează și este foarte apreciat!
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Cum pot lua legătura cu Liga Studenților?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Ne poți contacta prin intermediul paginilor noastre de social
                  media, prin e-mail sau prin formularul de contact disponibil
                  pe site-ul nostru oficial. Suntem mereu deschiși să răspundem
                  întrebărilor și să ajutăm cu informații.{" "}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  De ce să fiu voluntar LSAC?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{

                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Calitatea de voluntar al Ligii Studenților Facultății de
                  Automatică și Calculatoare Iași îți oferă mai multe beneficii.
                  Cel mai important dintre ele considerăm că este legat de
                  oamenii pe care ajungi să îi cunoști și lucrurile pe care la
                  poți învăța de la aceștia. Desigur, statutul de voluntar activ
                  îți aduce beneficii pentru cazarea în căminele din Campusul
                  Studențesc Tudor Vladimirescu ce au locuri rezervate pentru
                  AC, dar poate reprezenta și un factor semnificativ la
                  angajare.{" "}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Cum devin voluntar LSAC?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  În fiecare an organizăm sesiuni de înscriere pentru studenți,
                  aceștia având posibilitatea să aleaga cel mult două
                  departamente în cadrul cărora consideră că ar dori să
                  activeze, iar pe baza unui interviu vor fi acceptați sau nu.
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Cum este structurată Liga?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  LSAC are în componența sa bord-ul (conducerea) format din:
                  Președinte, Vicepreședinte Relații Externe, Secretar și doi
                  cenzori, dar și mai multe departamente, acestea fiind: Design,
                  FR (Fund Raising), HR (Human Resources), IT și PR (Public
                  Relations).
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Ce se întâmplă în cadrul departamentelor Ligii?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Departamentele Ligii Studenților Facultății de Automatică și
                  Calculatoare au responsabilități unice, însă sunt
                  interdependente. Iată descrierea pe scurt pentru cele cinci
                  departamente principale:
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Departamentul Design – Creează materiale vizuale atractive
                  pentru promovarea evenimentelor și activităților Ligii
                  Studenților, asigurând o identitate vizuală coerentă.
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Departamentul Fund Raising (FR) – Se ocupă de atragerea de
                  sponsorizări și parteneriate pentru a sprijini desfășurarea
                  proiectelor și evenimentelor organizate de Liga Studenților.
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Departamentul Human Resources (HR) – Se ocupă de organizarea
                  evenimentelor din punct de vedere logistic, gestiunea forței
                  de lucru și realizarea materialelor necesare pentru buna
                  desfășurare a evenimentelor.
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Departamentul IT – Gestionează infrastructura tehnică a
                  organizației, dezvoltând și întreținând platforme digitale și
                  soluții IT pentru a facilita activitatea Ligii.
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Departamentul Public Relations (PR) – Promovează imaginea
                  Ligii și comunică eficient cu studenții și partenerii prin
                  canalele de social media și alte mijloace de comunicare
                  publică.
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Cine poate face parte din Liga Studenților?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Orice student din cadrul universităților din Iași poate face
                  parte din LSAC, indiferent de anul de studiu sau de nivelul de
                  experiență.
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Ce fel de evenimente organizează Liga Studenților?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Organizăm o gamă variată de evenimente, cum ar fi workshop-uri
                  pe teme tehnice, sesiuni de training pentru dezvoltarea
                  abilităților soft, competiții și hackathoane, precum și
                  activități sociale și recreative, pentru a crea o comunitate
                  unită și implicată.
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "rgba(42, 157, 244, 0.3)",
                  width: "100%",
                  border: "2px solid",
                  borderColor: "rgba(42, 157, 244, 1)",
                  borderRadius: "24px",
                  padding: "16px 64px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 2rem)",
                    },
                  }}
                >
                  Cum sunt folosite fondurile strânse de Liga Studenților?
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "clamp(1rem, 30vw, 1.3rem)",
                      md: "clamp(1.5rem, 30vw, 1.5rem)",
                    },
                  }}
                >
                  Fondurile strânse de Liga Studenților sunt folosite pentru a
                  organiza evenimente și proiecte destinate comunității
                  studențești și pentru a susține activitățile noastre
                  administrative. Scopul nostru este să oferim cât mai multe
                  oportunități de dezvoltare personală și profesională pentru
                  studenți.
                </Typography>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      </Grid2>
      <BTT />
      <Footer />
    </ThemeProvider>
  );
};

export default FAQ;
